export default 
[
    { text: '写一份1000字逃课上网的检讨书', reply: '\
尊敬的领导：\n\
\n\
我深刻反思自己的行为，认识到创造一个优秀的人才需要良好的品德和坚实的学识。而我的逃课上网行为严重影响了学业和自身发展。在此，我向领导和老师们诚挚致歉，并表示深深的悔过和反思。\n\
\n\
作为一名大学生，应该认真学习，不断提高自己的专业水平和自身素质。但是，在近日的学习生活中，不仅没有认真听讲，反而选择逃课上网。我知道自己的行为严重地破坏了自己的学业和未来的发展，既浪费了宝贵的时间，又使自己在学业上落后了其他同学，这是一种非常错误的做法。\n\
\n\
我清楚地记得，我曾多次在课堂上拾起手机上网、听音乐或者溜出教室到外面玩耍。我深知自己的这些行为给别人和我自己都带来了极大的不利影响，尤其对于我自己来说，我错过了很多重要的课程内容，这使我无法真正掌握自己的专业知识。\n\
\n\
同时，我也清楚地知道，我的行为严重地背离了大学生应有的品德和责任感。作为一名学生，我应该认真履行自己的责任，努力学习专业知识并提高自己的思想素质，以更好地为社会作出贡献。但是，我的逃课上网行为让我深感愧疚，我必须认真反思自己，明确自己的学习方向，以积极的心态和态度去面对自己的不足，为以后的学习生活打下坚实的基础。\n\
\n\
在此，我真诚地向领导们、老师们以及经过这段时间与我相处的同学们致以深深的歉意。我感谢你们一直以来对我的帮助和支持，也深切地认识到，我必须采取积极的行动来弥补这段时间的过错，提高自己的学习成绩和综合素质。\n\
\n\
我将以更加严谨的态度，更加努力的精神去思考自己的学业，更加勤奋的态度去学习专业知识，以实际行动来证明自己的决心和信念。\n\
\n\
再次向大家表达我的诚挚谢意和悔过之情。同时，我期待着大家的宽容、理解和支持，愿意接受大家的指导和建议，为自己的未来和社会的发展做出更好的贡献。\n\
\n\
谨此致敬礼！\n\
\n\
敬礼！\n\
\n\
XXX\n\
\n\
日期：XXXX年XX月XX日\n\
    ' },
    { text: '告诉我10种哄女朋友开心的方法', reply: '\
1. 给她送花或小礼物；\n\
2. 带她去吃她最爱的美食或喝她最爱的饮料；\n\
3. 陪她看电影或电视剧；\n\
4. 带她去旅游或野外露营；\n\
5. 安排一个浪漫的晚餐或烛光晚餐；\n\
6. 给她写情书或小卡片；\n\
7. 帮她做家务或购物；\n\
8. 给她按摩或泡个脚；\n\
9. 送她去体验一项自己喜欢的活动；\n\
10. 给她讲笑话或逗她开心。\n\
    ' },
    { text: '世界大学的排名？', reply: '以下是一些全球著名的大学排名榜单：\
    以下是几个著名的世界大学排名榜单：\
\n\
1. QS世界大学排名\n\
QS (Quacquarelli Symonds)发布的世界大学排名被认为是最具代表性和权威性的排名之一，以教学、研究、国际化、学术声誉4个指标进行评估。\n\
\n\
2. 《泰晤士报》世界大学排名\n\
The Times Higher Education (THE)发布的世界大学排名被认为是最受关注和最权威的排名之一，以教学、研究、国际化、引用等指标进行评估。\n\
\n\
3. 《美国新闻与世界报道》全球大学排名\n\
U.S. News & World Report发布的世界大学排名被认为是全球排名中最主要的排名之一，以教学质量、研究、国际化等指标进行评估。\n\
\n\
4. CWUR世界大学排名\n\
Center for World University Rankings (CWUR)发布的世界大学排名主要关注研究成果、学术声誉和学生教育等指标进行评估。\n\
\n\
需要注意的是，不同排名机构所使用的评价标准和方式不同，因此排名结果可能存在主观性和局限性。\n\
    ' }
]