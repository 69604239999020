<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import './assets/css/reset.css'
import './assets/css/theme.css'
import './assets/css/global.css'

export default {
  name: 'App',
}
</script>