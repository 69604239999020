export default
[
    {
        text: '帮我写一份800字的道歉信', reply: '\
尊敬的XXX领导：\n\
\n\
非常抱歉给您带来了不便，我是您们公司的一名员工，因为我的过失导致公司损失了一定的财产，我深感愧疚和内疚。在此我向公司及您致以深深的歉意和感谢。\n\
\n\
我的这支部门由于人手不足及外来的客户工作量繁重等原因，我受到了很大的压力，对于最近这次事故的原因，其中也包括了我自身的工作压力以及对工作中某些方面的理解不够深入等原因。正是因为我的疏忽，才导致了这次经济损失的出现，我感到非常的心疼。因为我知道，这样的事情将会直接影响到企业的形象及公司发展中的进度。\n\
\n\
然而，我此次的错误证明了我必须更加加强工作态度，思考更加全面入微的方案来帮助公司解决突发状况。我已经深入反省了自己的工作内容，并认真查找问题，寻找问题的根源，希望能给公司带来真正的反思和启迪，从而避免和缓解这样的事故在将来发生。\n\
\n\
在这里，我再次向您和公司深深的道歉。我发誓，今后我会更加努力工作，尽力为公司服务，在各个方面精益求精，切实履行我的职责，并以更有责任感的态度，弥补公司因此次损失而产生的影响。\n\
\n\
最后，再次向您和公司致以深深的歉意。我希望公司还能给我一次机会，让我证明自己的价值，为公司的发展贡献自己的力量。\n\
\n\
祝公司更加蓬勃发展！\n\
\n\
此致\n\
\n\
敬礼\n\
\n\
XXX\n\
\n\
日期：XXXX年XX月XX日\n\
        '
    },
    {
        text: '把大象装进冰箱，总共分几步？', reply: '\
将大象装进冰箱的步骤分为三步：\n\
\n\
1. 打开冰箱门：这一步很简单，只需打开冰箱门。\n\
\n\
2. 放入大象：这是最关键的一步，将大象放入冰箱。这可能需要一些助力和工具，如起重机或滑轮。需要确保大象能够安全地放入并且没有受伤。\n\
\n\
3. 关上冰箱门：最后一步是将冰箱门关上，确保大象安全地保留在冰箱中。\n\
\n\
需要注意的是，将大象装进冰箱的这个问题实际上是个谐音梗，其意思是类似“简单的事情也需要步骤”这样的道理。\n\
        '
    },
    {
        text: '解释一下什么是收支两条线', reply: '收支两条线指的是个人或组织的计划收入和实际支出之间的差距，二者分别以一条线的形式表示。收支两条线是财务管理中常用的一个概念，可以用来帮助人们更好地了解自己的财务状况和制定合理的预算计划。当收入线高于支出线时，意味着个人或组织有盈余；当支出线高于收入线时，则代表出现财务赤字。通过不断调整和优化收支两条线，可以使个人或组织的财务状况变得更加健康和稳定。'
    }
]