export default
[
    { text: '写一首歌词，表达对远方恋人的思念', reply: '\
Verse 1:\n\
时光荏苒流逝，岁月如梭\n\
远方的你离我好遥远\n\
千山万水仍难阻挡\n\
思念的心情随风飘荡\n\
\n\
Pre-Chorus:\n\
虽然远隔千里之遥\n\
我还是会时常想起你的微笑\n\
每一个夜晚独自躺着\n\
心里满是对你的挂念\n\
\n\
Chorus:\n\
在这远方，我始终想念\n\
你那温柔的眼神\n\
每当我闭上双眼\n\
你的影像在脑海中浮现\n\
\n\
Verse 2:\n\
月亮高挂在空中，星光闪耀\n\
思念依旧回旋在深夜\n\
心中充盈着满满的爱\n\
奔走不已，放不下你的背影\n\
\n\
Pre-Chorus:\n\
虽然远隔千里之遥\n\
我还是会时常想起你的微笑\n\
每一个夜晚独自躺着\n\
心里满是对你的挂念\n\
\n\
Chorus:\n\
在这远方，我始终想念\n\
你那温柔的眼神\n\
每当我闭上双眼\n\
你的影像在脑海中浮现\n\
\n\
Bridge:\n\
即使距离遥远\n\
我始终如一的守候\n\
只要你还在我的心中\n\
我们依旧在一起\n\
\n\
Chorus:\n\
在这远方，我始终想念\n\
你那温柔的眼神\n\
每当我闭上双眼\n\
你的影像在脑海中浮现\n\
\n\
Outro:\n\
时光荏苒流逝，岁月如梭\n\
我始终如一的爱着你\n\
让思念陪伴你身旁\n\
直到我们重逢的那一天\n\
    ' },
    { text: '世界上最好的工作在哪个公司？', reply: '\
这个问题的答案会因人而异。不同人对“最好的工作”有不同的理解和标准。有些人可能认为高薪水和福利待遇是最好的工作的标志，而另一些人可能看重工作的挑战性、创造性和发展空间。然而，以下几家公司被广泛认为是在各自领域内提供最优秀工作机会的公司：\n\
\n\
1. 谷歌（Google）：谷歌是全球知名的互联网公司，总部位于美国加利福尼亚州的硅谷。谷歌被认为是许多人心目中最宜居的公司之一，公司文化开放、自由，为员工提供广泛的福利和发展机会。\n\
\n\
2. 苹果（Apple）：苹果被公认为是科技界内最受尊敬和领先的公司之一，总部位于美国加州库比蒂诺市。苹果深受员工喜爱，因为它为员工提供了许多福利待遇和开放、创新的工作环境。\n\
\n\
3. 亚马逊（Amazon）：亚马逊是全球最大的电子商务公司之一，也是最有名的科技公司之一。亚马逊为员工提供广泛的福利、培训和发展机会，使员工能够在创新和拓展业务方面获得巨大的成就。\n\
\n\
需要注意的是，每个人的职业目标和发展路线是不一样的，最优秀的工作应该根据个人的专业背景、兴趣和职业规划来评价和选择，最好的工作和公司也会因人和时代而异。\
    ' },
    { text: '怎么样用 ffmpeg 实现视频编码', reply: '使用 FFmpeg 实现视频编码需要以下几个步骤：\n\
    \n\
1. 安装 FFmpeg\n\
\n\
在终端中输入以下命令来安装 FFmpeg：\n\
\n\
```bash\n\
sudo apt-get install ffmpeg\n\
```\n\
\n\
2. 输入 FFmpeg 命令\n\
\n\
使用 FFmpeg 进行视频编码的命令语法如下：\n\
\n\
```bash\n\
ffmpeg -i input_file.mp4 -c:v h264 -b:v 1000k -c:a aac -b:a 128k output_file.mp4\n\
```\n\
\n\
其中参数的含义如下：\n\
\n\
- `-i input_file.mp4`: 输入文件名\n\
- `-c:v h264`: 视频编码器设置为 H.264\n\
- `-b:v 1000k`: 视频码率为 1000k\n\
- `-c:a aac`: 音频编码器设置为 AAC\n\
- `-b:a 128k`: 音频码率为 128k\n\
- `output_file.mp4`: 输出文件名\n\
\n\
例如，要将一个名为 `input.mp4` 的视频文件编码为 H.264 格式，同时设置视频码率为 1000k，音频码率为 128k，输出文件名为 `output.mp4`，则输入以下命令：\n\
\n\
```bash\n\
ffmpeg -i input.mp4 -c:v h264 -b:v 1000k -c:a aac -b:a 128k output.mp4\n\
```\n\
\n\
3. 运行 FFmpeg 命令进行编码\n\
\n\
在终端中输入 FFmpeg 命令，然后按下回车键即可开始编码。编码完成后，输出文件 `output.mp4` 将保存在当前工作目录中。\n\
\n\
需要注意的是，FFmpeg 可以进行各种复杂的视频编码操作，可以通过阅读 FFmpeg 文档或在网上搜索相关例子来获得更多的信息和指导。' }
]