<template>
    <div>
        <section id="index" class="container">
            <section id="userBalance" class="container">
                <header class="comm-title">
                    <h2>
                        <span class="c-333">您是 {{ vipname }} </span><br />
                        <span class="c-333">ChatGPT剩余次数 {{ gptrestcount }} 次 </span><br />
                        <span class="c-333">SwdlGPT剩余次数 {{ selfgptrestcount }} 次 </span><br />
                        <span class="c-333">AI绘画剩余次数 {{ stabilityrestcount }} 次 </span><br />
                        <span class="c-333">到期时间 {{ vipendtime }} </span>
                    </h2>
                    <br />
                </header>
            </section>

            <section v-show="vipPayShow">
                <ul>
                    <table style="text-align: center;width: 80%; font-size: x-large;">
                        <tr>
                            <td><br /></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>特权对比</td>
                            <td v-for="product in vipProductList" :key="product.id">
                                <a :class="[
                                    'orderBtn',
                                    { current: payOrder.productId === product.id },
                                ]" @click="selectItem(product.id)" href="javascript:void(0);">
                                    {{ product.title }}
                                    ¥{{ product.price / 100 }}
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td v-for="product in vipProductList" :key="product.id">
                                <el-button :disabled="payBtnDisabled" type="warning" round
                                    style="width: 180px; height: 44px; font-size: 18px" @click="toPayVIP(product.id)">
                                    立即开通
                                </el-button>
                            </td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>ChatGPT数量</td>
                            <td>20次</td>
                            <td>100次</td>
                            <td>1000次</td>
                            <td>不限次数</td>
                        </tr>
                        <br/>
                        <tr>
                            <td>SwdlGPT数量</td>
                            <td>100次</td>
                            <td>500次</td>
                            <td>5000次</td>
                            <td>不限次数</td>
                        </tr>
                    </table>
                </ul>
            </section>

            <el-dialog :visible.sync="codeDialogVisible" :show-close="false" @close="closeDialog" width="350px" center>
                <qriously :value="codeUrl" :size="300" />
                使用微信扫码支付
            </el-dialog>

        </section>

    </div>
</template>
  
<script>

import productApi from "../../api/product";
import wxPayApi from "../../api/wxPay";
import orderInfoApi from "../../api/orderInfo";
import userApi from '../../api/user';
import { EventBus } from '@/main.js'


export default {
    components: {
    },

    data() {
        return {
            payBtnDisabled: false, //确认支付按钮是否禁用
            codeDialogVisible: false, //微信支付二维码弹窗
            vipProductList: [],
            payOrder: {
                //订单信息
                productId: "", //商品id
                payType: "wxpay", //支付方式
                userId: "",
            },
            codeUrl: "", // 二维码
            orderNo: "", //订单号
            timer: null, // 定时器
            balance: "",
            vipname: "",
            vipPayShow: true,
            endTime: "",
            vipendtime: "",
            gptrestcount: 0,
            selfgptrestcount:0,
            stabilityrestcount:0,
            hasOneCharge: false
        };
    },
    //页面加载时执行
    created() {


        //获取商品列表
        productApi.list().then((response) => {
            this.productList = response.data.productList;
            this.vipProductList = this.productList.filter((item) => {
                return item.producttype == 'VIP'
            })
            this.payOrder.userId = localStorage.getItem("userId");
        });

        //#region 用户检查
        var userName = localStorage.getItem("userName");
        if (userName == "" || userName == null || userName == "null") {
            // this.$router.push({ path: "/gpt" });
        }
        else {

            this.queryUserInfo()
        }

        var tab = this.$route.query.tab;
        if (tab == "charge") {
            this.activeName = "third";
        }
        //#endregion



        EventBus.$on('queryUserInfo', () => {
            this.queryUserInfo()
        })

    },

    methods: {
        //选择商品
        selectItem(productId) {
            console.log("商品id：" + productId);
            this.payOrder.productId = productId;
            console.log(this.payOrder);
        },

        //选择支付方式
        selectPayType(type) {
            console.log("支付方式：" + type);
            this.payOrder.payType = type;
        },

        //确认支付
        toPayVIP(productId) {
            if (this.checkUserLogin()) {
                this.payOrder.payType = 'wxpay';
                if (this.hasOneCharge == true && productId == 5) {
                    this.warningTips("1元体验仅可体验1次")
                }
                else {
                    if (localStorage.getItem("userId") == "") {
                        this.warningTips("您还没有登录，请登录后再使用")
                        // this.$router.push({ path: "/gpt" });
                    } else {
                        //禁用按钮，防止重复提交
                        this.payBtnDisabled = true;

                        //微信支付
                        if (this.payOrder.payType === "wxpay") {
                            //调用统一下单接口
                            wxPayApi.nativePay(productId, this.payOrder.userId).then((response) => {
                                this.codeUrl = response.data.codeUrl;
                                this.orderNo = response.data.orderNo;

                                //打开二维码弹窗
                                this.codeDialogVisible = true;

                                //启动定时器
                                this.timer = setInterval(() => {
                                    //查询订单是否支付成功
                                    this.queryOrderStatus();
                                }, 300);
                            });
                        }
                    }
                }
            }

        },

        //关闭微信支付二维码对话框时让“确认支付”按钮可用
        closeDialog() {
            this.payBtnDisabled = false;
            clearInterval(this.timer);
        },

        // 查询订单状态
        queryOrderStatus() {
            orderInfoApi.queryOrderStatus(this.orderNo).then((response) => {
                console.log("查询订单状态：" + response.code);
                // 支付成功后的页面跳转
                if (response.code === 0) {
                    clearInterval(this.timer);
                    this.$message({
                        message: '支付成功，请跳转到聊天页面与ChatGPT对话',
                        type: 'success'
                    });
                    // 三秒后跳转到订单列表
                    setTimeout(() => {
                        this.$router.push({ path: "/gpt" });
                        this.$router.go(0);
                    }, 2000);
                }
            });
        },


        queryUserInfo() {
            if (this.checkUserLogin()) {
                userApi.queryUserByID(localStorage.getItem("userId")).then((response) => {

                    this.balance = response.data.userInfo.balance;
                    this.viplevel = response.data.userInfo.viplevel;
                    this.vipname = response.data.userInfo.vipname;

                    const dateString = response.data.userInfo.vipendtime;
                    if (dateString != null & dateString != "") {
                        const date = new Date(Date.parse(dateString));
                        const formattedDate = date.toLocaleDateString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
                        this.vipendtime = formattedDate;
                    }
                    else {
                        this.vipendtime = "";
                    }
                    this.gptrestcount = response.data.userInfo.gptrestcount;
                    this.selfgptrestcount = response.data.userInfo.selfgptrestcount
                    this.stabilityrestcount = response.data.userInfo.stabilityrestcount
                    if (this.vipendtime != "") {
                        this.hasOneCharge = true
                    }
                })
            }
        },
        checkUserLogin() {
            if (localStorage.getItem("userId") != "" && localStorage.getItem("userId") != null) {
                return true
            }
            else {
                this.warningTips("您还没有登录，请登录后再使用")
                return false
            }
        },
        warningTips(content) {
      this.$message({
        message: content,
        type: 'warning'
      });
    },



    },
};

</script>

