import { render, staticRenderFns } from "./ExampleSelf.vue?vue&type=template&id=49b4eb54&"
import script from "./ExampleSelf.vue?vue&type=script&lang=js&"
export * from "./ExampleSelf.vue?vue&type=script&lang=js&"
import style0 from "./ExampleSelf.vue?vue&type=style&index=0&id=49b4eb54&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports