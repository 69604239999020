import { render, staticRenderFns } from "./Text2Img.vue?vue&type=template&id=72932abe&"
import script from "./Text2Img.vue?vue&type=script&lang=js&"
export * from "./Text2Img.vue?vue&type=script&lang=js&"
import style0 from "./Text2Img.vue?vue&type=style&index=0&id=72932abe&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports