<template>
    <div style="text-align: center;" class="centered">
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <h2>AI绘画</h2>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="1">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="22">
                <div class="grid-content bg-purple-light">
                    <div style="margin-top: 15px;text-align: left;">
                        <el-row>
                            <el-column>
                                图片创意：
                            </el-column>
                            <el-column>
                                <el-input placeholder="输入一段话，AI将实现您的创意" v-model="text" style="width: 60%;" >
                                </el-input>
                            </el-column>
                        </el-row>


                        <label style="font-size: medium;color: lightgray;">示例：一只昂首挺胸的大公鸡 |或| 雨中的荷花 |或| 一个撑着油纸伞的姑娘</label>

                    </div>
                    <!-- <div style="text-align: left;">
                        <h4></h4>
                    </div> -->
                    <div style="text-align: left;">
                        图片尺寸：
                        <el-radio-group v-model="resolution">
                            <el-radio-button label="1024*1024"></el-radio-button>
                            <el-radio-button label="1024*1536"></el-radio-button>
                            <el-radio-button label="1536*1024"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <!-- <div style="text-align: left;">
                        <h4>图片风格：</h4>
                    </div> -->
                    <p>&nbsp;</p>
                    <div style="text-align: left;">
                        图片风格：
                        <el-radio-group v-model="style">
                            <el-radio-button label="写实风格"></el-radio-button>
                            <el-radio-button label="探索无限"></el-radio-button>
                            <el-radio-button label="古风"></el-radio-button>
                            <el-radio-button label="二次元"></el-radio-button>
                            <el-radio-button label="浮世绘"></el-radio-button>
                            <el-radio-button label="low poly"></el-radio-button>
                            <el-radio-button label="未来主义"></el-radio-button>
                            <el-radio-button label="像素风格"></el-radio-button>
                            <el-radio-button label="概念艺术"></el-radio-button>
                            <br/>
                            <el-radio-button label="赛博朋克"></el-radio-button>
                            <el-radio-button label="洛丽塔风格"></el-radio-button>
                            <el-radio-button label="巴洛克风格"></el-radio-button>
                            <el-radio-button label="超现实主义"></el-radio-button>
                            <el-radio-button label="水彩画"></el-radio-button>
                            <el-radio-button label="蒸汽波艺术"></el-radio-button>
                            <el-radio-button label="油画"></el-radio-button>
                            <el-radio-button label="卡通画"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <div>
                        <!-- <br /> -->
                        <el-button slot="append" icon="el-icon-s-promotion" style="width: 200px; font-size:large;"
                            type="primary" size="medium" @click="getImg">
                            立 即 创 作
                        </el-button>
                    </div>
                </div>
            </el-col>
            <el-col :span="1">
                <!-- <div class="grid-content bg-purple"></div> -->
            </el-col>
        </el-row>
        <!-- <br /> -->
        <center>
            <table style="text-align: center;" :width="tableWitdh">
                <tr>
                    <!-- 百度 -->
                    <td><img :src="base64ImgData2" :width="tableWitdh * 0.6"></td>
                    <td>&nbsp;</td>
                    <td><img :src="base64ImgData3" :width="tableWitdh * 0.6"></td>
                </tr>

                <tr style="text-align: center;">
                    <!-- 百度 -->
                    <td style="text-align: center;"><el-button v-on:click="saveImg2" v-show="baiduButtonShow"
                            type="primary">下载</el-button></td>
                    <td></td>
                    <td style="text-align: center;"><el-button v-on:click="saveImg3" v-show="baiduButtonShow"
                            type="primary">下载</el-button></td>
                </tr>

                <tr>
                    <!-- Stability -->
                    <td><img :src="base64ImgData" :width="tableWitdh * 0.6"></td>
                    <td></td>
                    <td><img :src="base64ImgData1" :width="tableWitdh * 0.6"></td>
                </tr>

                
                <tr>

                    <!-- Stability -->
                    <td style="text-align: center;"><el-button v-on:click="saveImg" v-show="stabilityButtonShow"
                            type="primary">下载</el-button></td>
                    <td></td>
                    <td style="text-align: center;"><el-button v-on:click="saveImg1" v-show="stabilityButtonShow"
                            type="primary">下载</el-button></td>

                </tr>
            </table>
        </center>

    </div>
</template>
<script>
import axios from 'axios'
import request from '@/utils/requestGPT'
import { Loading } from 'element-ui';
export default {
    data() {
        return {

            API_KEY: 'dtxg8SB4LZ4Ifgfn2HyRV58z',
            SECRET_KEY: 'EKqjsef3qIZZondng8HkDgQ1pnbnSTkG',

            text: "",
            resolution: "1024*1024",
            style: "写实风格",
            num: 2,
            taskId: 0,

            base64Data: '',
            base64Data1: '',
            base64Data2: '',
            base64Data3: '',

            base64ImgData: '',
            base64ImgData1: '',
            base64ImgData2: '',
            base64ImgData3: '',


            stabilityButtonShow: false,
            baiduButtonShow: false,

            stabilityloading: false,
            baiduloading: false,

            tableWitdh: 0,
            timerId: null
        }
    },
    methods: {
        getStabilityImage() {
            if (this.text == "")
                return;
            var that = this;
            Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
            this.stabilityloading = true;
            axios.post('https://api.stability.ai/v1/generation/stable-diffusion-v1/text-to-image', {
                cfg_scale: 7,
                clip_guidance_preset: "FAST_BLUE",
                height: 512,
                width: 512,
                sampler: "K_DPM_2_ANCESTRAL",
                samples: 5,
                steps: 75,
                text_prompts: [
                    {
                        "text": this.text + " 风格 " + this.style,
                        "weight": 1
                    }
                ]
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'sk-VqAiGxuOSRLeQjuuNkThHhz0i8F3eSpLdAXdnbmmapuEe0UE'
                }
            })
                .then(response2 => {
                    that.base64Data = response2.data.artifacts[0].base64;
                    that.base64Data1 = response2.data.artifacts[1].base64;
                    that.base64ImgData = 'data:image/png;base64,' + response2.data.artifacts[0].base64;
                    that.base64ImgData1 = 'data:image/png;base64,' + response2.data.artifacts[1].base64;
                    if (that.base64ImgData != "") {
                        that.stabilityButtonShow = true;
                        that.stabilityloading = false;
                    }
                    if (!that.stabilityloading && !that.baiduloading) {
                        let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
                        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            loadingInstance.close();
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getBaiduImg() {
            if (this.text == "")
                return;
            Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
            this.baiduloading = true
            var content = { "text": this.text, "resolution": this.resolution, "style": this.style, "num": this.num }
            request({
                url: '/BaiduPaintQueryHandler',
                data: JSON.stringify(content),
                method: 'post'
            }).then(response => {
                if (response.data.taskId > 0) {
                    this.taskId = response.data.taskId
                    this.startTimer()
                }
            }).catch(error => {
                console.log(error)
            })
        },

        getBaiduImgResult() {
            if (this.taskId == 0)
                return;
            var content = { "taskId": this.taskId }
            request({
                url: '/BaiduPaintResultHandler',
                data: JSON.stringify(content),
                method: 'post'
            }).then(response => {
                if (response.data.imgUrls.length > 0) {
                    this.base64ImgData2 = response.data.imgUrls[0].image;
                    this.base64ImgData3 = response.data.imgUrls[1].image;
                    this.stopTimer()
                    this.taskId = 0;
                    this.baiduButtonShow = true;
                    this.baiduloading = false;

                    if (!this.stabilityloading && !this.baiduloading) {
                        let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
                        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            loadingInstance.close();
                        });
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        },


        getImg() {
            var userid = localStorage.getItem("userId");
            if (userid == "" || userid == null) {
                this.warningTips("您还没有登录，请登录后再使用")
            }
            //查询剩余次数
            request({
                url: '/getStabilityVIPCount?userid=' + userid,
                method: 'get'
            }).then(response => {
                if (response == 0 || response < 0) {
                    this.warningTips("您的图像生成次数已用完，请到会员界面进行充值")
                    return false;
                }
                else {
                    this.getStabilityImage();
                    this.getBaiduImg();
                    request({
                        url: '/updateStabilityVIPCount?userid=' + userid,
                        method: 'get'
                    })
                }
            }).catch(error => {
                console.log(error)
            })
        },




        startTimer() {
            // Use setInterval to execute a function every second
            this.timerId = setInterval(() => {
                this.getBaiduImgResult();
            }, 1000)
        },
        stopTimer() {
            // Use clearInterval to stop the timer
            clearInterval(this.timerId)
        },




        base64ToBlob(base64) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], { type: 'image/png' }); // Change the type accordingly
        },
        saveBlobAsFile(blob, fileName) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        },
        saveBase64Img(base64String) {
            const blob = this.base64ToBlob(base64String);
            this.saveBlobAsFile(blob, 'image'); // Replace with your desired file name
        },
        saveSrcImg(imgSrc) {
            fetch(imgSrc)
                .then(res => res.blob().then(blob => {
                    const a = document.createElement('a'),	// 动态创建a标签，防止下载大文件时，用户没看到下载提示连续点击
                        url = window.URL.createObjectURL(blob),
                        filename = 'image';
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }));
        },




        saveImg() {
            this.saveBase64Img(this.base64Data)
        },
        saveImg1() {
            this.saveBase64Img(this.base64Data1)
        },
        saveImg2() {
            this.saveSrcImg(this.base64ImgData2)
        },
        saveImg3() {
            this.saveSrcImg(this.base64ImgData3)
        },

        successTips(content) {
            this.$message({
                message: content,
                type: 'success'
            });
        },
        warningTips(content) {
            this.$message({
                message: content,
                type: 'warning'
            });
        },
    },
    created() {
        this.tableWitdh = window.innerWidth * 0.5; // adjust the percentage as needed
    }
}

</script>

<style>
html,
body {
    height: 100%;
}

.centered {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>