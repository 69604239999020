<template>
  <div>
    <div>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <Example v-show="!isChatOpen"></Example>
            <br />
            <beautiful-chat :always-scroll-to-bottom="alwaysScrollToBottom" :close="closeChat" :colors="colors"
              :is-open="isChatOpen" :message-list="chatList" :message-styling="messageStyling"
              :new-messages-count="newMessagesCount" :on-message-was-sent="onMessageWasSent" :open="openChat"
              :participants="participants" :show-close-button="false" :show-launcher="false" :show-emoji="false"
              :show-file="false" :show-typing-indicator="showTypingIndicator" :show-edition="false" :show-deletion="false"
              :disable-user-list-toggle="true" @onType="handleOnType" @edit="editMessage" @remove="removeMessage">
            </beautiful-chat>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  
<script>
import chatParticipants from './chatProfiles'
import availableColors from './colors'
import request from '@/utils/requestGPT'
import requestSelfGPT from '@/utils/requestSelfGPT'
import messageHistory from './messageHistory'
import Example from './ExampleSelf.vue'
import { EventBus } from '@/main.js'

export default {
  components: {
    Example,
  },
  name: 'App',
  data() {
    return {
      participants: chatParticipants,
      chatList: messageHistory,
      newMessagesCount: 0,
      isChatOpen: true,
      showTypingIndicator: '',
      colors: null,
      availableColors,
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      userIsTyping: false,
      gptList: [],
      gptFirstMsg: true,
      gptReturnEnd: false,
      countNum: "",
      historyList: []
    }
  },
  computed: {
    linkColor() {
      return this.chosenColor === 'dark' ? this.colors.sentMessage.text : this.colors.launcher.bg
    },
    backgroundColor() {
      return this.chosenColor === 'dark' ? this.colors.chatList.bg : '#ddd'
    }
  },
  created() {
    this.setColor('blue')
    if (this.gptList.length <= 2) {
      this.isChatOpen = false
    }
    EventBus.$on('exampleSelfInput', (params) => {
      this.exampleSelfInput(params.param1, params.param2)
    })
  },
  methods: {
    queryResult(msgSend) {
      var that = this
      requestSelfGPT({
        url: '/result',
        data: JSON.stringify(msgSend),
        method: 'post'
      }).then(response => {
        if (!this.gptFirstMsg) {
          this.chatList.pop()
        }
        var messageResponse = { "author": "chatGPT", "type": "text", "data": { "text": response.data.response } }
        this.chatList = [...this.chatList, Object.assign({}, messageResponse, { id: Math.random() })]
        var s2 = { "history": that.historyList, "content": response.data.response, "userid": msgSend.user_id, "query_id": this.countNum.toString() }
        this.gptList.push(s2)
        if (this.gptList.length > 7) {
          this.gptList.shift()
        }

        if (response.data.state == 1) {
          console.log(response.data.state)
          this.gptReturnEnd = true
          var requestData = msgSend.query
          var responseData = response.data.response
          if(responseData.length>5000)
          {
            responseData = responseData.slice(0, responseData.length / 2);
          }
          var msgPair = [requestData,responseData]
          // var msgPair = '(\'' + requestData + '\',\'' + responseData + '\')'
          if(that.historyList == null)
          {
             that.historyList.push(msgPair)
          }
          if (msgPair.toString() != that.historyList[that.historyList.length-1]) {
            that.historyList.push(msgPair)
            // alert("1:::"+msgPair.toString())
          }
          else{
            // alert(msgPair.toString())
          }
          if (this.historyList.length > 7) {
            this.historyList.shift()
          }
        }
        else
        {
          console.log(response.data.state)
        }
        this.gptFirstMsg = false

      })
    },
    onMessageWasSent(message) {
      var that = this
      var userid = localStorage.getItem("userId");
      if (userid == "" || userid == null) {
        this.warningTips("您还没有登录，请登录后再使用")
      }
      else {
        //查询剩余次数
        request({
          url: '/getUserSelfVIPCount?userid=' + userid,
          method: 'get'
        }).then(response => {
          if (response == 0 || response < 0) {
            this.warningTips("您的聊天次数已用完，请到会员界面进行充值")
            return false;
          }
          else {
            this.chatList = [...this.chatList, Object.assign({}, message, { id: Math.random() })]
            this.countNum = new Date().getTime();
            var msgSend = { "history": that.historyList, "query": message.data.text, "user_id": userid, "query_id": this.countNum.toString() }
            this.gptList.push(msgSend)
            if (this.gptList.length > 7) {
              this.gptList.shift()
            }
            requestSelfGPT({
              url: '/main',
              data: JSON.stringify(msgSend),
              method: 'post'
            }).then(response => {
              if (response.code == 0) {
                that.gptFirstMsg = true
                that.gptReturnEnd = false
                that.queryResult(msgSend)
                let intervalId = setInterval(function () {
                  if (that.gptReturnEnd) {
                    console.log("end")
                    clearInterval(intervalId)
                  }
                  else {
                    that.queryResult(msgSend)
                    console.log("goon")
                  }
                }, 100);
              }
              else {
                // alert(3)
              }
            }).catch(error => {
              console.log(error)
            })
            //查询剩余次数
            request({
              url: '/getUserSelfVIPCount?userid=' + userid,
              method: 'get'
            }).then(response => {
              if (response == 5) {
                this.warningTips("您的聊天次数剩余5")
              }
              else if (response == 0) {
                this.warningTips("您的聊天次数已用完，请到会员界面进行充值")
                request({
                  url: '/UpdateUserVIPToNormalCountHandler?userid=' + userid,
                  method: 'get'
                })
              }
            }).catch(error => {
              console.log(error)
            })
            request({
              url: '/updateUserSelfVIPCount?userid=' + userid,
              method: 'get'
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },

    openChat() {
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat() {
      this.isChatOpen = false
    },
    setColor(color) {
      this.colors = this.availableColors[color]
      this.chosenColor = color
    },
    handleOnType() {
      this.$root.$emit('onType')
      this.userIsTyping = true
    },
    editMessage(message) {
      const m = this.chatList.find((m) => m.id === message.id)
      m.isEdited = true
      m.data.text = message.data.text
    },
    removeMessage(message) {
      if (confirm('Delete?')) {
        const m = this.chatList.find((m) => m.id === message.id)
        m.type = 'system'
        m.data.text = 'This message has been removed'
      }
    },
    exampleSelfInput(inputContent, outputContent) {
      this.isChatOpen = true
      //添加消息
      var messageAutoSend = { "author": "me", "type": "text", "data": { "text": inputContent } }
      this.chatList = [...this.chatList, Object.assign({}, messageAutoSend, { id: Math.random() })]
      var userid = localStorage.getItem("userId");
      if (userid == "" || userid == null) {
        this.warningTips("您还没有登录，请登录后再使用")
        if (outputContent != "") {
          //添加回复消息
          var chatAutoResponse = { "author": "chatGPT", "type": "text", "data": { "text": outputContent } }
          this.chatList = [...this.chatList, Object.assign({}, chatAutoResponse, { id: Math.random() })]
          var gptMsg = { "history": this.historyList, "content": outputContent, "userid": userid, "query_id": this.countNum.toString() }
          this.gptList.push(gptMsg)
        }
      }
      else {
        //查询剩余次数
        request({
          url: '/getUserSelfVIPCount?userid=' + userid,
          method: 'get'
        }).then(response => {
          if (response == 0 || response < 0) {
            this.warningTips("您的聊天次数已用完，请到会员界面进行充值")
            return false;
          }
          else {
            // 添加快捷问消息
            if (this.gptList.length > 7) {
              this.gptList.shift()
            }
            if (outputContent != "") {
              //添加回复消息
              var chatAutoResponse = { "author": "chatGPT", "type": "text", "data": { "text": outputContent } }
              this.chatList = [...this.chatList, Object.assign({}, chatAutoResponse, { id: Math.random() })]
            }
            else {
              var that = this
              // var message = { "author": "chatGPT", "type": "text", "data": { "text": outputContent } }
              // this.chatList = [...this.chatList, Object.assign({}, message, { id: Math.random() })]
              this.countNum = new Date().getTime();
              var msgSend = { "history": this.historyList, "query": inputContent, "user_id": userid, "query_id": this.countNum.toString() }
              this.gptList.push(msgSend)
              if (this.gptList.length > 7) {
                this.gptList.shift()
              }
              requestSelfGPT({
                url: '/main',
                data: JSON.stringify(msgSend),
                method: 'post'
              }).then(response => {
                if (response.code == 0) {
                  that.gptFirstMsg = true
                  that.gptReturnEnd = false
                  that.queryResult(msgSend)
                  let intervalId = setInterval(function () {
                    that.queryResult(msgSend)
                    if (that.gptReturnEnd) {
                      console.log("end")
                      clearInterval(intervalId);
                    }
                    else {
                      console.log("goon")
                    }
                  }, 100);
                }
                else {
                  // alert(3)
                }
              }).catch(error => {
                console.log(error)
              })
            }
            if (this.gptList.length > 7) {
              this.gptList.shift()
            }
            //查询剩余次数
            request({
              url: '/getUserSelfVIPCount?userid=' + userid,
              method: 'get'
            }).then(response => {
              if (response == 5) {
                this.warningTips("您的聊天次数剩余5")
              }
              else if (response == 0) {
                this.warningTips("您的聊天次数已用完，请到会员界面进行充值")
                request({
                  url: '/UpdateUserVIPToNormalCountHandler?userid=' + userid,
                  method: 'get'
                })
              }
            }).catch(error => {
              console.log(error)
            })

            //更新消息数量
            request({
              url: '/updateUserSelfVIPCount?userid=' + userid,
              method: 'get'
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    successTips(content) {
      this.$message({
        message: content,
        type: 'success'
      });
    },
    warningTips(content) {
      this.$message({
        message: content,
        type: 'warning'
      });
    },
  }
}
</script>
