export default
[
  {
    id: 'chatGPT',
    name: 'chatGPT',
    imageUrl: 'http://www.swdl.net.cn/static/gpt.png'
  }
  ,
  {
    id: 'me',
    name: 'me',
    imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
  }
]