<template>
    <div style="text-align: center;" class="centered">
        <br /><br /><br /><br /><br /><br /><br /><br />
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <h1>SwdlGPT</h1>
                </div>
            </el-col>
        </el-row>
        <br /><br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple"><i class="el-icon-s-order" style="font-size: 40px;"></i></div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-light"><i class="el-icon-question" style="font-size: 40px;"></i></div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple"><i class="el-icon-s-management" style="font-size: 40px;"></i></div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>

        <br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-light">
                    <h3>AI创作</h3>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-light">
                    <h3>有趣的问题</h3>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-light">
                    <h3>AI百科</h3>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
        </el-row>

        <br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>

            <el-col :span="6" v-for="(button, index) in buttons1" :key="index">
                <div class="grid-content bg-purple-light">
                    <el-button style="width: 260px;" ref="myButton" @click="sendToChatWindow(button)">
                        {{ button.text + "→" }}
                    </el-button>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>

        <br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>



            <el-col :span="6" v-for="(button, index) in buttons2" :key="index">
                <div class="grid-content bg-purple-light">
                    <el-button style="width: 260px;" ref="myButton" @click="sendToChatWindow(button)">
                        {{ button.text + "→" }}
                    </el-button>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>

        <br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="6" v-for="(button, index) in buttons4" :key="index">
                <div class="grid-content bg-purple-light">
                    <el-button style="width: 260px;" ref="myButton" @click="sendToChatWindow(button)">
                        {{ button.text + "→" }}
                    </el-button>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>

        <br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>

            <el-col :span="6" v-for="(button, index) in buttons5" :key="index">
                <div class="grid-content bg-purple-light">
                    <el-button style="width: 260px;" ref="myButton" @click="sendToChatWindow(button)">
                        {{ button.text + "→" }}
                    </el-button>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>

        <br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="6" v-for="(button, index) in buttons3" :key="index">
                <div class="grid-content bg-purple-light">
                    <el-button style="width: 260px;" ref="myButton" @click="sendToChatWindow(button)">
                        {{ button.text + "→" }}
                    </el-button>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>

        <br />
        <el-row>
            <el-col :span="3">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="18">
                <div class="grid-content bg-purple-light">
                    <div style="margin-top: 15px;">
                        <el-input placeholder="请输入内容" v-model="searchContent" class="input-with-select"
                            @keyup.enter.native="sendContentToChatWindow">
                            <el-button slot="append" icon="el-icon-s-promotion" style="color:blue;"
                                @click="sendContentToChatWindow">
                            </el-button>
                        </el-input>
                    </div>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>

    </div>
</template>
<script>
import { EventBus } from '@/main.js'
import Demo1 from './DemoMessage/Demo1'
import Demo2 from './DemoMessage/Demo2'
import Demo3 from './DemoMessage/Demo3'
import Demo4 from './DemoMessage/Demo4'
import Demo5 from './DemoMessage/Demo5'
export default {
    data() {
        return {
            count: 0,
            buttons1: Demo1,
            buttons2: Demo2,
            buttons3: Demo3,
            buttons4: Demo4,
            buttons5: Demo5,
            searchContent: ""
        }
    },
    methods: {
        sendToChatWindow(button) {
            EventBus.$emit('exampleSelfInput', { param1: button.text, param2: button.reply })
        },
        sendContentToChatWindow() {
            if (this.searchContent != "") {
                EventBus.$emit('exampleSelfInput', { param1: this.searchContent, param2: "" })
            }
        }
    }
}
</script>

<style>
html,
body {
    height: 100%;
}

.centered {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>