export default
    [
        {
            text: '帮忙写一首情诗', reply: '\
爱是一种力量，让我们有勇气面对生活中的悲欢离合。\n\
爱是一种信仰，让我们有坚定的信念，走过漫长的岁月。\n\
爱是一种美好，让我们在生命的旅途中感受到真正的幸福。\n\
\n\
在我生命中的每一天，\n\
你就是我的影子，永远伴随在身边。\n\
如果有一天，我的心灵因为某种原因失落，\n\
你就是我的力量，让我重新坚定信念。\n\
\n\
我一生中的每一个瞬间，\n\
都与你的影子紧密相连。\n\
我爱你，不只是因为你的美丽，\n\
而是因为你的善良和全心全意的爱。\n\
\n\
在这个世界上，我最幸福的事情就是和你在一起，\n\
和你在一起的每一刻都是如此美好和珍贵。\n\
我想在这个世界上，和你共同度过每一个日日夜夜，\n\
直到我们的生命在这个世界上无声地结束。\n\
\n\
你是我生命中的阳光，给我带来无限的欢乐和温暖。\n\
我的世界因为有了你而变得更加美好和充实。\n\
我会一生一世珍惜你，与你携手到永远。\n\
\n\
爱你，是我一生中最美好的事情，\n\
我会一生一世守护着你，给你爱的温暖和力量。\n\
让我们一起走过这段旅程，永不言败，永不离开。\n\
\n\
    ' },
        {
            text: '什么职业可以月薪过万？', reply: '\
有很多职业可以月薪过万，以下列举几个常见的职业：\n\
\n\
1. IT行业的软件工程师、数据分析师、产品经理等，在大城市的一线互联网公司，月薪过万常见；\n\
2. 金融行业的投资银行家、基金经理、证券分析师等，金融行业从业人员的薪水相对偏高；\n\
3. 医生、律师、会计师等需要高学历和专业认证的职业，在经验丰富的情况下以及所在的城市和工作行业、机构等条件而定，月薪过万也是很常见的；\n\
4. 媒体、广告、公关等行业的高端职位，如高级记者、资深策划、营销总监等也有可能月薪过万；\n\
5. 自主创业的成功者往往也可以月入过万。\n\
\n\
当然，不同城市、不同行业、不同公司、甚至同一行业的不同职位、经验、能力、机会等各种因素都会对月薪产生影响，以上只是一些常见的月薪过万的职业。\n\
            ' },
        {
            text: '什么是ChatGPT，可以做什么', reply: '\
ChatGPT是一种基于人工智能技术的聊天机器人，可以与用户进行自然语言交互，并提供各种服务和答案。\n\
\n\
ChatGPT使用大规模预训练的语言模型GPT（Generative Pre-trained Transformer）作为基础，可以继续训练和优化以适应不同领域和应用场景的需求。ChatGPT还能够对用户的问题和答案进行理解和归纳，从而提供更加智能和精准的回答。\n\
\n\
ChatGPT能够应用于多个领域，例如客服、咨询、教育、娱乐等，可以帮助企业和个人提高效率、降低成本、提高用户体验等方面的需求。\n\
        ' }
    ]