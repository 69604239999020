<template>
  <div style="text-align: center;width:100%;margin:0 auto;">
    <ul class="nav" id="exitButton" v-show="!displayLogin">
      <label id="welcome">欢迎&nbsp;{{ nickname }}&nbsp;|&nbsp;您是 {{ vipname }} </label>
      &nbsp;
      <a @click="exitLogin()">退出</a>
    </ul>
    <!-- <br/>
        <el-button :disabled="loginDisable" type="primary"  v-show="displayLogin" id="loginRegButton" style="width: 150px;height: 50px;"
          @click="WeChatLogin()">登录/注册</el-button> -->
  </div>
</template>
  
  
<script>

import userApi from "../../api/user";
import { AuthenticationClient } from "authing-js-sdk";


var userName = localStorage.getItem("userName");
var displayLogin = true;
if (userName != "" && userName != null) {
  displayLogin = false;
} else {
  displayLogin = true;
}

self.setInterval(function () {
  this.nickname = localStorage.getItem("nickName")
}, 1000);



export default {
  data() {
    return {
      userName: localStorage.getItem("userName"),
      displayLogin: displayLogin,
      loginDisable: false,
      nickname: localStorage.getItem("nickName"),
      vipname: ""
    };
  },

  created() {
    var userid = localStorage.getItem("userId");
    if (userid != null && userid != "") {
      userApi.queryUserByID(userid).then((response) => {
        if (response.code === 0 && response.data.userInfo != null) {
          this.vipname = response.data.userInfo.vipname;
        }
      })
    }
    else {
      // this.WeChatLogin()
    }
  },
  methods: {
    exitLogin: function () {
      localStorage.setItem("userName", "");
      localStorage.setItem("userId", "");
      localStorage.setItem("passWord", "");
      userName = ""

      this.$router.push({ path: "/gpt" });
      this.$router.go(0)
      displayLogin = true;
    },


    WeChatLogin: function () {
      this.loginDisable = true;
      setTimeout(() => {
        this.loginDisable = false;
      }, 4000);

      const authenticationClient = new AuthenticationClient({
        appId: "62882ff42bb2e3ac469b18c6",
        appHost: 'https://swdl.authing.cn',
      });
      authenticationClient.social.authorize("swdl", {
        onSuccess: (user) => {
          userApi.queryUserByWxID(user.id).then((response) => {
            if (response.code === 0 && response.data.userInfo != null) {
              setTimeout(() => {

                localStorage.setItem("userName", response.data.userInfo.username);
                localStorage.setItem("passWord", response.data.userInfo.password);
                localStorage.setItem("userId", response.data.userInfo.id)
                localStorage.setItem("nickName", response.data.userInfo.nickname)
                this.$router.push({ path: "/gpt" });
                this.$router.go(0)
              }, 1000);
            }
            else {
              userApi.register(user.id, user.nickname, "123456").then((response) => {
                if (response.code === 0 && response.data.userInfo != null) {
                  localStorage.setItem("userName", response.data.userInfo.username);
                  localStorage.setItem("passWord", response.data.userInfo.password);
                  localStorage.setItem("userId", response.data.userInfo.id);
                  localStorage.setItem("nickName", response.data.userInfo.nickname);
                  setTimeout(() => {
                    this.$router.push({ path: "/gpt" });
                    this.$router.go(0);
                  }, 3000);
                }
              }).catch(e => {
                console.log(e)
              });
            }
          })
        },
        onError: (code, message) => {
          console.log(code)
          console.log(message)
        },
      });
    },
  },
};
</script>
   