import request from '@/utils/request'




export default{
 
  //查询订单列表
  list(userId) {
    return request({
      url: '/api/order-info/listByUser/'+userId,
      method: 'post'
    })
  },

  queryOrderStatus(orderNo) {
    return request({
      url: '/api/order-info/query-order-status/' + orderNo,
      method: 'post'
    })
  }
}
