// 创建应用程序的路由器
import Vue from 'vue'
import VueRouter from 'vue-router'
// 此时就可以在Vue实例中配置路由器了
Vue.use(VueRouter)

// 引入组件
import ChatMain from '../views/demo/ChatMain'

// 创建并暴露一个路由器
export default new VueRouter({
    routes:[
        {
            path: '/',
            component: ChatMain
        },
        {
            path:'/gpt',
            component:ChatMain
        },
    ]
})