<template>
  <div>
    <el-row>

    </el-row>
    <el-row>
      <el-col :span="3">
        <div>
          <el-menu style="height: 100vh;min-width: 150px;max-width:150px" default-active="1" class="el-menu-vertical-demo"
            background-color="#1B2D47" text-color="#fff">
            <el-menu-item index="1" @click="handleLogin" :disabled="loginDisable">
              <i class="el-icon-chat-line-square"></i>
              <span slot="title">登录系统</span>
            </el-menu-item>
            <el-menu-item index="2" @click="handleChat">
              <i class="el-icon-chat-line-square"></i>
              <span slot="title">ChatGPT体验</span>
            </el-menu-item>
            <el-menu-item index="5" @click="handleSelfChat">
              <i class="el-icon-chat-line-square"></i>
              <span slot="title">SwdlGPT体验</span>
            </el-menu-item>
            <el-menu-item index="6" @click="handleTxt2Img">
              <i class="el-icon-chat-line-square"></i>
              <span slot="title">AI绘画</span>
            </el-menu-item>
            <el-menu-item index="3" @click="handleVIP">
              <i class="el-icon-user"></i>
              <span slot="title">升级会员</span>
            </el-menu-item>
            <el-menu-item index="4" @click="handleContact">
              <i class="el-icon-phone-outline"></i>
              <span slot="title">联系客服</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>

      <el-col :span="21" style="text-align: center; vertical-align: middle;">
        <Login />
        <div v-show="vipShow">
          <VIP />
        </div>
      </el-col>

      <el-col :span="21" style="vertical-align: middle;">
        <div v-show="chatShow">
          <ChatDiag />
        </div>
      </el-col>

      <el-col :span="21" style="vertical-align: middle;">
        <div v-show="selfChatShow">
          <ChatDiagSelf />
        </div>
      </el-col>

      <el-col :span="21" style="vertical-align: middle;">
        <div v-show="txt2imgShow">
          <Txt2Img />
        </div>
      </el-col>

    </el-row>


    <div class="qrcode-modal" v-if="contactShow">
      <div class="qrcode-content">
        <img src="../../assets/img/qrcode.jpg" alt="二维码图片"><br />
        <h2>微信扫码 联系客服</h2><br />
        <el-button @click="hideQRCode">关闭</el-button>
      </div>
    </div>



  </div>
</template>

  
<script>
import ChatDiag from './ChatDiagNew.vue'
import Login from './Login.vue'
import VIP from './VIP.vue'
import request from '@/utils/requestGPT'
import userApi from "../../api/user";
import { AuthenticationClient } from "authing-js-sdk";
import { EventBus } from '@/main.js'
import ChatDiagSelf from './ChatDiagSelf.vue';
import Txt2Img from './Text2Img.vue'

export default {
  components: {
    ChatDiag,
    Login,
    VIP,
    ChatDiagSelf,
    Txt2Img
},
  data() {
    return {
      screenHeight: 0,
      loginShow: true,
      vipShow: false,
      chatShow: true,
      selfChatShow:false,
      loginDisable: false,
      contactShow: false,
      txt2imgShow:false,
    }
  },
  created() {
    this.screenHeight = document.documentElement.clientHeight;

    var userName = localStorage.getItem("userName");
    if (userName != "" && userName != null) {
      this.loginDisable = true;
    } else {
      this.loginDisable = false;
    }


  },
  methods: {
    handleChat(key, keyPath) {
      this.chatShow = true;
      this.loginShow = false;
      this.vipShow = false;
      this.selfChatShow=false;
      this.txt2imgShow=false;
      console.log(key, keyPath);
      var userName = localStorage.getItem("userName");
      if (userName != "" && userName != null) {
        var userid = localStorage.getItem("userId");
        //查询剩余次数
        request({
          url: '/getUserVIPCount?userid=' + userid,
          data: JSON.stringify(this.gptList),
          method: 'get'
        }).then(response => {
          if (response > 0) {
            this.chatShow = true;
          }
          else {
            this.chatShow = true;
          }
        })
      }
    },

    handleSelfChat(key, keyPath) {
      this.chatShow = false;
      this.selfChatShow=true;
      this.loginShow = false;
      this.vipShow = false;
      console.log(key, keyPath);
      var userName = localStorage.getItem("userName");
      if (userName != "" && userName != null) {
        var userid = localStorage.getItem("userId");
        //查询剩余次数
        request({
          url: '/getUserVIPCount?userid=' + userid,
          data: JSON.stringify(this.gptList),
          method: 'get'
        }).then(response => {
          if (response > 0) {
            this.selfChatShow = true;
          }
          else {
            this.selfChatShow = true;
          }
        })
      }
    },

    handleTxt2Img(key, keyPath){
      this.chatShow = false;
      this.selfChatShow=false;
      this.loginShow = false;
      this.vipShow = false;
      this.txt2imgShow=true;
      console.log(key, keyPath);
    },

    handleVIP(key, keyPath) {
      this.chatShow = false;
      this.selfChatShow=false;
      this.loginShow = false;
      this.vipShow = true;
      this.txt2imgShow=false;
      console.log(key, keyPath);
      EventBus.$emit('queryUserInfo')
    },
    handleLogin(key, keyPath) {
      this.WeChatLogin()
      console.log(key, keyPath);
    },
    handleContact(key, keyPath) {
      this.showQRCode()
      console.log(key, keyPath);
    },
    WeChatLogin: function () {
      this.loginDisable = true;
      setTimeout(() => {
        this.loginDisable = false;
      }, 4000);
      const authenticationClient = new AuthenticationClient({
        appId: "62882ff42bb2e3ac469b18c6",
        appHost: 'https://swdl.authing.cn',
      });
      authenticationClient.social.authorize("swdl", {
        onSuccess: (user) => {
          userApi.queryUserByWxID(user.id).then((response) => {
            if (response.code === 0 && response.data.userInfo != null) {
              setTimeout(() => {

                localStorage.setItem("userName", response.data.userInfo.username);
                localStorage.setItem("passWord", response.data.userInfo.password);
                localStorage.setItem("userId", response.data.userInfo.id)
                localStorage.setItem("nickName", response.data.userInfo.nickname)
                this.$router.push({ path: "/gpt" });
                this.$router.go(0)
              }, 1000);
            }
            else {
              userApi.register(user.id, user.nickname, "123456").then((response) => {
                if (response.code === 0 && response.data.userInfo != null) {
                  localStorage.setItem("userName", response.data.userInfo.username);
                  localStorage.setItem("passWord", response.data.userInfo.password);
                  localStorage.setItem("userId", response.data.userInfo.id);
                  localStorage.setItem("nickName", response.data.userInfo.nickname);
                  setTimeout(() => {
                    this.$router.push({ path: "/gpt" });
                    this.$router.go(0);
                  }, 3000);
                }
              }).catch(e => {
                console.log(e)
              });
            }
          })
        },
        onError: (code, message) => {
          console.log(code)
          console.log(message)
        },
      });
    },
    showQRCode() {
      this.contactShow = true;
    },
    hideQRCode() {
      this.contactShow = false;
    }

  }
}
</script>

<style scoped>
.qrcode-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.qrcode-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fff;
  padding: 20px;
}

.qrcode-content img {
  width: 400px;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>